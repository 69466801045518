<template>
  <div class="flex flex-1 overflow-hidden text-xs">
    <div class="flex flex-col w-1/2">

      <div class="relative flex items-center flex-shrink-0 px-4 py-2 border-t border-b border-gray-200 group">
        <div class="absolute ml-2">
          <font-awesome-icon class="text-gray-500" :icon="['far', 'search']" />
        </div>
        <input class="w-full" style="padding-left:28px;" v-model="filter" placeholder="Metodenavn">
        <button v-if="filter" @click.prevent="filter = ''" class="absolute right-6 focus:outline-none">
          <font-awesome-icon class="text-gray-500" :icon="['far', 'times']" />
        </button>
      </div>

      <div class="p-4 overflow-y-auto">
        <div 
          v-for="(method, index) in filteredMethods" 
          :key="index"
        >
          <button 
            @click="method.isOpen = !method.isOpen"
            class="flex items-center justify-between w-full mt-2 bg-gray-300 hover:bg-orange-300 focus:outline-none"           
            :class="{ 'bg-orange-300 text-orange-900 hover:bg-orange-300': method.isOpen }"
          >
            <div class="flex items-center p-2">
              <div 
                class="px-2 font-semibold text-gray-100 bg-gray-400 rounded-full"
                :class="{ 'bg-orange-400 text-orange-100': method.isOpen }">
                {{ method.verb.toUpperCase() }}
              </div>
              <div class="ml-2 font-semibold">                
                {{ method.name }}
              </div>
            </div>
            <div class="p-2" :class="{ 'rotate-180': method.isOpen }">
              <font-awesome-icon :icon="['far', 'chevron-down']" />
            </div>
          </button>
          <div class="p-2 text-orange-900 bg-orange-100" v-if="method.isOpen">
            <table>
              <!-- <tr>
                <td>
                  <input type="checkbox"  />
                </td>
              </tr> -->
            <tr v-for="(arg, argindex) in method.args" :key="argindex">
              <td><input type="checkbox" v-model="arg.include" /></td>
              <td class="p-1 pl-2">{{ arg.name }} <span class="text-orange-500">({{ arg.type }})</span></td>
              <td class="p-1 pl-2">
                <template v-if="arg.type === 'bool' || arg.type === 'bool?'">
                  <input type="checkbox" v-model="arg.value" />
                </template>
                <template v-else-if="
                  arg.typeNaked === 'AddressStatus' || 
                  arg.typeNaked === 'AddressType' || 
                  arg.typeNaked === 'ApiOptions' || 
                  arg.typeNaked === 'ApiFormat' ||
                  arg.typeNaked === 'InvoiceLevel' ||
                  arg.typeNaked === 'FeeType' ||
                  arg.typeNaked === 'Folder' ||
                  arg.typeNaked === 'SearchSource' ||
                  arg.typeNaked === 'SearchMode' ||
                  arg.typeNaked === 'TransactionType' ||
                  arg.typeNaked === 'ProductType' ||
                  arg.typeNaked === 'WorkerStatus' ||
                  arg.typeNaked === 'ReportLevel' ||
                  arg.typeNaked === 'UserStatus' ||
                  arg.typeNaked === 'MenuVisibility' ||
                  arg.typeNaked === 'MenuPermission' ||
                  arg.typeNaked === 'UserLevel' ||
                  arg.typeNaked === 'LocationType'">
                  <select v-model="arg.value" :disabled="!arg.include">
                    <option :value="status.value" v-for="(status, asindex) in enums[arg.typeNaked].values" :key="asindex">
                      ({{ status.value }}) {{ status.name }}
                    </option>
                  </select>
                </template>
                <!-- <template v-else-if="arg.type === 'AddressType[]'">
                  <select v-model="arg.value">
                    <option :value="type.value" v-for="(type, atindex) in enums.AddressType.values" :key="atindex">
                      {{ type.name }}
                    </option>
                  </select>
                </template> -->
                <template v-else>
                  <input 
                    v-model="arg.value" 
                    :disabled="!arg.include"
                  />
                </template>
              </td>         
              <td>                
                <template v-if="arg.name.indexOf('locationId') !== -1">
                  <button class="px-1 hover:bg-orange-200 focus:outline-none" @click.prevent="arg.value = location.id">
                    {{ location.id }}
                  </button>
                </template>
                <template v-else-if="arg.name.indexOf('customerId') !== -1">
                  <button class="px-1 hover:bg-orange-200 focus:outline-none" @click.prevent="arg.value = customer.id">
                    {{ customer.id }}
                  </button>
                </template>
                <template v-else-if="arg.name.indexOf('portalType') !== -1">
                  <button class="px-1 hover:bg-orange-200 focus:outline-none" @click.prevent="arg.value = 'PORTAL'">
                    PORTAL
                  </button>
                </template>
                <template v-else-if="arg.name.indexOf('userId') !== -1">
                  <button class="px-1 hover:bg-orange-200 focus:outline-none" @click.prevent="arg.value = user.id">
                    {{ user.id }}
                  </button>
                </template>
                
              </td>     
            </tr>
            <tr>
              <td></td>
              <td class="p-1">Include <span class="text-orange-500">(string)</span></td>
              <td class="p-1 pl-2"><input v-model="method.include" /></td>
              <td>
                <button class="px-1 hover:bg-orange-200 focus:outline-none" @click.prevent="method.include = 'id,name'">
                  id,name    
                </button>
              </td>
            </tr>
            
            </table>
            <div class="flex justify-end">              
              <button 
                class="flex items-center h-8 px-2 py-1 font-semibold text-white rounded focus:outline-none hover:bg-green-400" 
                :class="{ 'bg-gray-400 cursor-not-allowed': loading === method.name, 'bg-green-500': loading !== method.name }"
                :disabled="loading === method.name"
                @click="execute(method)"
              >
                <font-awesome-icon v-if="loading === method.name" :icon="['far', 'spinner']" pulse />
                <div v-else>Execute</div>
              </button> 
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-1/2">
      <pre ref="output" class="flex-1 w-full overflow-auto text-green-200 bg-black resize-none focus:outline-none">{{ response }}</pre>
    </div>
  </div>
</template>

<script>
import http from "@/http"
import { map, filter, forEach } from "lodash"
import { mapGetters } from "vuex"

export default {
  name: "api",
  data () {
    return {
      methods: [],
      enums: [],
      response: null,      
      filter: "",
      loading: ""
    }
  },
  computed: {
    ...mapGetters([
      "customer",      
      "location",
      "user"
    ]),
    filteredMethods () {
      return filter(this.methods, method => method.name.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1)
    }    
  },
  methods: {
    async execute (method) {     
      let obj = method.include ? { include: method.include } : null
      forEach(method.args, arg => {
        if (obj === null) obj = {}        
        if (arg.include) {
          obj[arg.name] = arg.value        
        }        
      })
      
      let data = null
      if (obj !== null) {
        data = (method.verb === "get" || method.verb === 'delete') ? { params: obj } : obj
      }

      this.loading = method.name
      const response = await http[method.verb](`/${method.name}`, data).catch(error => this.error = error)
      this.loading = ""

      this.response = JSON.stringify(response, null, 2)

      this.$refs.output.scrollTo(0, 0)
    },

    async init () {      
      const api = await http.get("/api")
      this.methods = map(api.methods, method => {
        const index = method.indexOf("]")
        const verb = method.substr(1, index - 1).toLowerCase()
        const signature = method.substr(index + 2)
        const name = signature.substr(0, signature.indexOf("(")).toLowerCase()
        const argss = signature.substr(signature.indexOf("(") + 1).slice(0, -1)      
        const args = argss ? map(argss.split(", "), arg => {
          const parts = arg.split(" ")
          return {
            type: parts[0],
            typeNaked: parts[0].replace("[]", "").replace("?", ""),
            name: parts[1],
            include: true,
            value: null
          }
        }) : []

        return {
          verb,
          name,
          args, 
          signature,
          include: "",
          isOpen: false
        }
      })
      this.enums = await http.get("Enums")
    }
  },

  async created () {
    await this.init()
  },

  

  // watch: {
  //   location: {
  //     handler: async function () {
  //       await this.init()
  //     }, immediate: true
  //   }
  // }

}
</script>

<style scoped>

input, select, textarea {
  @apply border border-gray-300 shadow-sm py-1 px-2 rounded-sm;
}

input:focus, select:focus {
  @apply outline-none border-gray-400;
}

</style>